/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    code: "code",
    h2: "h2",
    pre: "pre",
    a: "a",
    blockquote: "blockquote",
    h3: "h3"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "if let"), " is a fancy ", React.createElement(_components.code, null, "if"), " condition."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "while let"), " is a fancy ", React.createElement(_components.code, null, "while"), " loop."), "\n"), React.createElement(_components.p, null, "They enter a codeblock if a destructuring assignment works.")), "\n", React.createElement(_components.p, null, "With the ", React.createElement(_components.code, null, "if let"), " syntax you combine an ", React.createElement(_components.code, null, "if"), " condition with a destructuring ", React.createElement(_components.code, null, "let"), " assignment."), "\n", React.createElement(_components.p, null, "Let’s unpack (pun intended) that sentence."), "\n", React.createElement(_components.h2, {
    id: "the-if-condition"
  }, "The ", React.createElement(_components.code, null, "if"), " condition"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let my_pick: Option<u8> = Some(3);\nif Some(3) == my_pick {\n    println!(\"Honeybadger don't care.\");\n}\n")), "\n", React.createElement(_components.p, null, "This line prints, alerting everyone that ", React.createElement(_components.code, null, "Some(3)"), " is equal to ", React.createElement(_components.code, null, "my_pick"), ", and more importantly that ", React.createElement(_components.a, {
    href: "https://youtu.be/4r7wHMg5Yjg"
  }, "honeybadgers don’t care"), "."), "\n", React.createElement(_components.h2, {
    id: "destructuring-assignments"
  }, "Destructuring assignments"), "\n", React.createElement(_components.p, null, "If array destructuring works with square brackets, and tuple destructuring works via parentheses, it makes sense an ", React.createElement(_components.code, null, "Option"), " could be destructured via ", React.createElement(_components.code, null, "Some"), "."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "A quick recap for array and tuple destructuring:"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let arr = [1, 2, 3];\nlet [one, two, three] = arr;\nprintln!(\"Counting: {}, {}, {}\", one, two, three);\n// Counting: 1, 2, 3\n\nlet tup = (\"Daniel\", \"Ricciardo\", 3);\nlet (first_name, last_name, racing_number) = tup;\nprintln!(\"{} {} races with the number: {}\", first_name, last_name, racing_number);\n// Daniel Ricciardo races with the number: 3\n"))), "\n", React.createElement(_components.p, null, "If you try to destructure what’s inside ", React.createElement(_components.code, null, "my_pick"), " to the ", React.createElement(_components.code, null, "number"), " variable with ", React.createElement(_components.code, null, "Some(number)"), ", Rust will not compile and show a warning:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let my_pick: Option<u8> = Some(3);\nlet Some(number) = my_pick;\nprintln!(\"Daniel Ricciardo, nicknamed 'the honeybadger' races with the number: {}\", number);\n// error\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-error"
  }, "error[E0005]: refutable pattern in local binding: `None` not covered\n")), "\n", React.createElement(_components.p, null, "Because ", React.createElement(_components.code, null, "my_pick"), " has the type of ", React.createElement(_components.code, null, "Option<u8>"), ", it will either be a ", React.createElement(_components.code, null, "Some(<u8>)"), " or a ", React.createElement(_components.code, null, "None"), ".\nThe piece of code above assumes there will always be some integer inside and neglects the possibility ", React.createElement(_components.code, null, "my_pick"), " is ", React.createElement(_components.code, null, "None"), "."), "\n", React.createElement(_components.p, null, "The Rust compiler won’t let you do that, it requires every possibile value for the ", React.createElement(_components.code, null, "Option"), " to be covered!"), "\n", React.createElement(_components.p, null, "In this contrived example, humans can see ", React.createElement(_components.code, null, "my_pick"), " will always be ", React.createElement(_components.code, null, "Some(3)"), ", but the compiler can’t."), "\n", React.createElement(_components.p, null, "We can use a ", React.createElement(_components.code, null, "match"), " to cover every possibility."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let my_pick: Option<u8> = Some(3);\nlet number = match my_pick {\n    Some(num) => num,\n    None => panic!(\"Oh no, no number!\")\n};\nprintln!(\"Daniel Ricciardo, nicknamed 'the honeybadger' races with the number: {}\", number);\n")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "We could have also been even more specific and only execute the ", React.createElement(_components.code, null, "println!"), " if the number inside the ", React.createElement(_components.code, null, "Option"), " was 3, while ignoring any other possibility."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let my_pick: Option<u8> = Some(3);\nlet number = match my_pick {\n    Some(3) => 3,\n    _ => panic!(\"Oh no, no number!\")\n};\nprintln!(\"Daniel Ricciardo, nicknamed 'the honeybadger' races with the number: {}\", number);\n"))), "\n", React.createElement(_components.p, null, "That’s a bit verbose, we don’t care about the ", React.createElement(_components.code, null, "None"), " case and only want to do something if ", React.createElement(_components.code, null, "my_pick"), " holds a ", React.createElement(_components.code, null, "Some"), "."), "\n", React.createElement(_components.p, null, "Even worse, the way this code is written, it depends on a ", React.createElement(_components.code, null, "Some"), " being there, else it will ", React.createElement(_components.code, null, "panic"), ".\nWhat we really want is to conditionally print that line.\nIf ", React.createElement(_components.code, null, "my_pick"), " is a ", React.createElement(_components.code, null, "Some"), ": print it, if it is ", React.createElement(_components.code, null, "None"), ": ignore that ", React.createElement(_components.code, null, "println"), " and continue with the program."), "\n", React.createElement(_components.h2, {
    id: "combining-an-if-condition-with-a-destructuring-attempt"
  }, "Combining an if condition with a destructuring attempt"), "\n", React.createElement(_components.p, null, "Combining that destructuring attempt with an ", React.createElement(_components.code, null, "if"), " condition gives us the ", React.createElement(_components.code, null, "if let"), " syntax."), "\n", React.createElement(_components.p, null, "It tries to destructure, if that destructure works, the attached codeblock is entered.\nIf it doesn’t work, the codeblock isn’t entered and the code continues."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let my_pick: Option<u8> = Some(3);\nif let Some(number) = my_pick {\n    println!(\"Daniel Ricciardo, nicknamed 'the honeybadger' races with the number: {}\", number);\n}\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Using ", React.createElement(_components.code, null, "if let"), " means less typing, less indentation, and less boilerplate code. However, you lose the exhaustive checking that ", React.createElement(_components.code, null, "match"), " enforces"), "\n", React.createElement("footer", null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/book"
  }, "The Rust Programming Language"), " book")), "\n"), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "In the case we only care about ", React.createElement(_components.code, null, "my_pick"), " holding a ", React.createElement(_components.code, null, "Some(3)"), ":"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let my_pick: Option<u8> = Some(3);\nif let Some(3) = my_pick {\n    println!(\"Daniel Ricciardo, nicknamed 'the honeybadger' races with the number: 3\");\n}\n"))), "\n", React.createElement(_components.h3, {
    id: "the-else-case"
  }, "The ", React.createElement(_components.code, null, "else"), " case"), "\n", React.createElement(_components.p, null, "As you would expect from an ", React.createElement(_components.code, null, "if"), " condition, it can be combined with ", React.createElement(_components.code, null, "else if"), " and ", React.createElement(_components.code, null, "else"), ".\nThe ", React.createElement(_components.code, null, "else if"), " condition can also be a conditional destructuring attempt with ", React.createElement(_components.code, null, "else if let"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let my_pick: Option<u8> = Some(33);\nif let Some(3) = my_pick {\n    println!(\"Daniel Ricciardo, nicknamed 'the honeybadger' races with the number: 3\");\n} else if let Some(33) = my_pick {\n  println!(\"Max, Max, Max, super Max, Max\");\n} else {\n  println!(\"Unknown driver\");\n}\n")), "\n", React.createElement(_components.p, null, "Here, ", React.createElement(_components.code, null, "my_pick"), " is ", React.createElement(_components.code, null, "Some(33)"), ".\nThe ", React.createElement(_components.code, null, "if"), " doesn’t succeed anymore, the ", React.createElement(_components.code, null, "else if"), " condition succeeds, printing a lyric from a ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=43HCYSXZ9GI"
  }, "Max Verstappen fansong"), "."), "\n", React.createElement(_components.p, null, "If ", React.createElement(_components.code, null, "my_pick"), " held yet another ", React.createElement(_components.code, null, "Some"), " or if it held ", React.createElement(_components.code, null, "None"), ", the ", React.createElement(_components.code, null, "else"), " block would execute."), "\n", React.createElement(_components.h2, {
    id: "do-it-again-and-again"
  }, "Do it again, and again"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "while let"), " syntax does almost the same thing.\nInstead of continuing on when the end of the conditional codeblock is reached, it checks the condition again.\nIf the destructuring that is attempted there works again, enter the codeblock again! Rinse, repeat."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "pop"), " method on a vector returns an ", React.createElement(_components.code, null, "Option"), ".\nThe code below uses ", React.createElement(_components.code, null, "while let"), " to print out the number if ", React.createElement(_components.code, null, "pop"), " returns a ", React.createElement(_components.code, null, "Some"), " with that number.\nIn the case where ", React.createElement(_components.code, null, "pop"), " returns ", React.createElement(_components.code, null, "None"), ", the codeblock will not be entered again and the loop stops."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let mut my_picks: Vec<u8> = vec![3, 33, 44];\nwhile let Some(num) = my_picks.pop() {\n    println!(\"{}\", num);\n}\n")), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "Like the regular ", React.createElement(_components.code, null, "while"), " loop,\n", React.createElement(_components.code, null, "while let"), " does not have an ", React.createElement(_components.code, null, "else if"), " or an ", React.createElement(_components.code, null, "else"), " case.")), "\n", React.createElement(_components.h2, {
    id: "not-limited-to-the-option-type"
  }, "Not limited to the ", React.createElement(_components.code, null, "Option"), " type"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "if let"), " and ", React.createElement(_components.code, null, "while let"), " doesn’t always need to try and destructure an ", React.createElement(_components.code, null, "Option"), "."), "\n", React.createElement(_components.p, null, "The following piece of code tries to destructure an ", React.createElement(_components.code, null, "Ok(3)"), " from a ", React.createElement(_components.code, null, "Result"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let my_pick: Result<u8, ()> = Ok(3);\nif let Ok(3) = my_pick {\n    println!(\"Daniel Ricciardo, nicknamed 'the honeybadger' races with the number: 3\");\n}\n")), "\n", React.createElement(_components.p, null, "The thing we try to destructure doesn’t have to be the kind of enum variant that holds something, simple enums variants work too!"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "enum SesameStreetChar {\n    Count,\n    // others\n}\nlet best_vampire = SesameStreetChar::Count;\nif let SesameStreetChar::Count = best_vampire {\n  println!(\"Ah, ah, ah!\");\n};\n")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "In the previous example, a straight comparison would fail because the enum doesn’t implement the ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/cmp/trait.PartialEq.html"
  }, React.createElement(_components.code, null, "PartialEq"), " trait"), "."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "if SesameStreetChar::Count == best_vampire {\n  println!(\"this does not compile\");\n}\n// error\n")), React.createElement(_components.p, null, React.createElement(_components.code, null, "if let"), " still works."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "if let SesameStreetChar::Count = best_vampire {\n  println!(\"boo!\");\n}\n"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
